body {
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #14213d;
}

#root {
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputs {
  display: flex;
}

.input-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputs input {
  width: 40px;
  text-align: center;
  margin: 10px 10px;
  font-size: 35px;
  padding: 10px;
  border: 3px solid #fca311;
  border-radius: 10px;
}

.circle {
  background: linear-gradient(to right, #6a040f, #d00000);
  box-shadow: 0 0 7px 1px red;
  width: 30px;
  height: 30px;
  border-radius: 20px;
}

.green-bg {
  background: linear-gradient(to right, #008000, #70e000);
  box-shadow: 0 0 7px 1px green;
}

.button {
  margin-left: 40px;
}

@media screen and (max-width: 980px) {
  .container {
    flex-direction: column;
  }
  .button {
    margin: 0;
  }

  .inputs {
    margin-bottom: 40px;
  }
}

.button button {
  background: linear-gradient(to right, #7f8c8d, #2c3e50);
  color: #fff;
  width: 70px;
  height: 70px;
  border-radius: 64px;
  padding: 10px;
  box-shadow: 0 0 7px 1px black;
  color: white;
  background-color: #000000;
  cursor: pointer;
}

.button svg {
  font-size: 40px;
  color: #6a040f;
}

.button button:hover {
  opacity: 0.5;
  box-shadow: none;
}

.alert {
  border: 3px solid #6a040f;
  background-color: #d00000;
  color: #6a040f;
  position: absolute;
  top: 55px;
  text-align: center;
  font-size: 40px;
  box-shadow: 0 0 7px 1px red;
}

.success {
  border-color: #008000;
  background-color: greenyellow;
  color: #008000;
  box-shadow: 0 0 7px 1px greenyellow;
  padding: 0 10px;
}

.alert path {
  color: #008000;
}
